import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { deleteNote } from "../../api/notes";
import PropTypes from "prop-types";

const DeleteNote = ({
    action,
    handleClose,
    fetchNotes,
    selectedNote,
    selectedAppName,
    selectedVersion,
}) => {
    const open = action === "delete";
    const handleDelete = async () => {
        if (selectedNote && selectedAppName && selectedVersion) {
            try {
                const res = await deleteNote(
                    selectedAppName,
                    selectedVersion,
                    selectedNote
                );
                if (res.status === "success") {
                    await fetchNotes();
                    handleClose();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Delete Note?</DialogTitle>
            <DialogContent>
                <div>
                    Are you sure you want a to delete the `{selectedNote}` note
                    from `{selectedVersion}` of {selectedAppName.toUpperCase()}{" "}
                    APP ?
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='primary'>
                    Cancel
                </Button>
                <Button
                    onClick={handleDelete}
                    color='secondary'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteNote.propTypes = {
    action: PropTypes.string,
    handleClose: PropTypes.func,
    fetchNotes: PropTypes.func,
    selectedNote: PropTypes.string,
    selectedAppName: PropTypes.string,
    selectedVersion: PropTypes.string,
};

export default DeleteNote;
