// Impliment all components except Nav and Footer here
import ClientsSelector from "../Calendar/ClientsSelector";
import { useState } from "react";
import useData from "../../hooks/useData";
import PropTypes from "prop-types";
import Calendar from "../Calendar";
import "./index.css";
import Appointments from "../Appointments";
import ControlPanel from "../ControlPanel/ControlPanel";

/**
 * @name Main
 * @returns Main body JSX.ELEMENT
 */
const Main = ({ isControlPanelOpen, user }) => {
    const [selectedClient, setSelectedClient] = useState(user);
    const { setTo, setFrom, setUser, apps, sync, users } = useData();

    const handleClientSelect = (clientId) => {
        setSelectedClient(clientId);
    };

    const { isAdminLoggedIn } = sessionStorage;

    return (
        <div id='main'>
            {!isControlPanelOpen ? (
                <>
                    <div className='row'>
                        {isAdminLoggedIn && (
                            <ClientsSelector
                                users={users}
                                onClientSelect={handleClientSelect}
                            />
                        )}
                        <Calendar
                            methods={{
                                selectedClient,
                                setTo,
                                setFrom,
                                setUser,
                            }}
                            clientId={selectedClient}
                        />
                    </div>
                    <div className='right'>
                        <Appointments
                            apps={apps}
                            sync={sync}
                        />
                    </div>
                </>
            ) : (
                <ControlPanel />
            )}
        </div>
    );
};

Main.propTypes = {
    isControlPanelOpen: PropTypes.bool,
    user: PropTypes.string,
};

export default Main;
