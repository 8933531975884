import config from "../main-config.json";
const URL = config.api_url;

/**
 * @name login
 */
export const login = async (username, password) => {
    const url = URL + "/users/login.php";
    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                clientId: username,
                pass: password,
            }),
        });
        return res.text();
    } catch (e) {
        console.error("[Login]: Failed to Post Username or Password" + e);
        return false;
    }
};

/**
 * @name logOut
 */
/**
 * @name fetchAppointments
 */
export const fetchAppointments = async (user, from, to) => {
    const url =
        URL +
        `/booking/get.php?clientId=${user}&startDate=${from}&endDate=${to}`;
    try {
        const res = await fetch(url);
        return await res.json();
    } catch (e) {
        console.error("[fetchAppointments]: Failed to Fetch Data" + e);
        return null;
    }
};

/**
 * @name fetchAllUsers
 */
export const fetchAllUsers = async () => {
    const url = URL + "/users/get.php";
    try {
        const res = await fetch(url);
        return await res.json();
    } catch (e) {
        console.error("[fetchAppointments]: Failed to Fetch Data" + e);
        return null;
    }
};

/**
 * @name fetchAllUsers
 */
export const fetchLastSyncInfo = async (user) => {
    const url = URL + `/sync/get.php?clientId=${user}`;
    try {
        const res = await fetch(url);
        return await res.json();
    } catch (e) {
        console.error("[fetchLastSyncInfo]: Failed to Fetch Data" + e);
        return null;
    }
};

/**
 * @name deleteUser
 */

export const deleteUser = async (user) => {
    const url = URL + "/users/delete.php";
    const { clientId, alias } = user;
    const body = JSON.stringify({
        clientId: clientId,
        alias: alias,
    });
    try {
        const res = await fetch(url, {
            method: "POST",
            body,
        });
        return await res.json();
    } catch (e) {
        console.error("[deleteUser]: Failed to Delete User" + e);
        return null;
    }
};

/**
 * @name updateUser
 */

export const updateUser = async (user, newClientId, newAlias, newPass) => {
    const url = URL + "/users/update.php";
    const { clientId, alias } = user;
    const oldClientId = clientId;
    const oldAlias = alias;
    const requestBody = {
        clientId: oldClientId,
        alias: oldAlias,
        ...(newClientId !== "" && { newId: newClientId }),
        ...(newAlias !== "" && { newAlias: newAlias }),
        ...(newPass !== "" && { newPass: newPass }),
    };

    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(requestBody),
        });
        return await res.json();
    } catch (e) {
        console.error("[updateUser]: Failed to Update User" + e);
        return null;
    }
};

/**
 * @name createUser
 */

export const createUser = async (clientId, alias, password) => {
    const url = URL + "/users/set.php";
    const requestBody = {
        clientId: clientId,
        alias: alias,
        pass: password,
    };

    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(requestBody),
        });
        return await res.json();
    } catch (e) {
        console.error("[createUser]: Failed to Create User" + e);
        return null;
    }
};
