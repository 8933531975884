import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import { editNote } from "../../api/notes";
import { useState } from "react";
import NewNoteInput from "./NewNoteInput";
import PropTypes from "prop-types";

const EditNote = ({
    action,
    handleClose,
    fetchNotes,
    selectedNote,
    selectedAppName,
    selectedVersion,
}) => {
    const [newNote, setNewNote] = useState(undefined);
    const open = action === "edit";

    const handleEditNote = async () => {
        if (selectedNote && selectedAppName && selectedVersion && newNote) {
            try {
                const res = await editNote(
                    selectedAppName,
                    selectedVersion,
                    selectedNote,
                    newNote
                );
                if (res.status === "success") {
                    await fetchNotes();
                    handleClose();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Edit Note</DialogTitle>
            <DialogContent>
                <div>
                    Edit the `{selectedNote}` note of `{selectedVersion}` from{" "}
                    {selectedAppName.toUpperCase()} APP ?
                </div>
            </DialogContent>
            <NewNoteInput
                value={newNote}
                setNewValue={setNewNote}
                label={"Edit Note"}
                id={"editNoteInput"}
            />
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='error'>
                    Cancel
                </Button>
                <Button
                    onClick={handleEditNote}
                    disabled={
                        newNote !== undefined &&
                        newNote !== "" &&
                        newNote.length < 5
                    }
                    color='primary'>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditNote.propTypes = {
    action: PropTypes.string,
    handleClose: PropTypes.func,
    fetchNotes: PropTypes.func,
    selectedNote: PropTypes.string,
    selectedAppName: PropTypes.string,
    selectedVersion: PropTypes.string,
};

export default EditNote;
