import { useState, useEffect } from "react";
import {
    fetchAllUsers,
    fetchAppointments,
    fetchLastSyncInfo,
    login,
} from "../api/index";
import { getAllAppsNotes } from "../api/notes";

const useData = () => {
    const [sync, setSync] = useState({});
    const [apps, setApps] = useState([]);
    const [users, setUsers] = useState([]);
    const [allAppsNotes, setAllAppsNotes] = useState([]);
    const [user, setUser] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(
        sessionStorage.getItem("isLoggedIn") === "true"
    );
    const [errorMessage, setErrorMessage] = useState("");

    const getData = async () => {
        if (user && from && to) {
            const appointments = await fetchAppointments(user, from, to);
            const lastSyncInfo = await fetchLastSyncInfo(user);

            if (appointments) setApps(appointments);
            if (lastSyncInfo) setSync(lastSyncInfo);
        }
    };

    const getUsers = async () => {
        const users = await fetchAllUsers();
        if (users) setUsers(users);
    };

    const fetchAllAppsNotes = async () => {
        const allAppsNotes = await getAllAppsNotes();
        if (allAppsNotes) setAllAppsNotes(allAppsNotes);
    };

    const handleLogin = async (username, password) => {
        try {
            const res = await login(username, password);
            if (res && res === "1") {
                setIsLoggedIn(true);
                sessionStorage.setItem("isLoggedIn", true);
                if (username === "gameover") {
                    sessionStorage.setItem("isAdminLoggedIn", true);
                }
                setErrorMessage("");
                sessionStorage.setItem("cubeCounterUser", username);
                setUser(username);
            } else {
                setErrorMessage("Wrong username or password");
            }
        } catch (e) {
            console.error("[Login]: Failed to Post Username or Password" + e);
            setErrorMessage(e.message);
        }
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isAdminLoggedIn");
    };

    useEffect(() => {
        getData();
    }, [user, from, to]);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        fetchAllAppsNotes();
    }, []);

    return {
        sync,
        setTo,
        getUsers,
        users,
        setFrom,
        setUser,
        apps,
        isLoggedIn,
        errorMessage,
        handleLogin,
        handleLogout,
        user,
        allAppsNotes,
        fetchAllAppsNotes,
    };
};

export default useData;
