import { TextField } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";

const NewNoteInput = ({ value, setNewValue, label, id }) => {
    const [newNoteError, setNewNoteError] = useState(null);
    const handleNewNoteInput = (event) => {
        const newValue = event.target.value;
        setNewValue(newValue);
        if (newValue === null || newValue === "") {
            setNewNoteError("The new note shouldn't be empty");
        } else if (newValue.length < 5) {
            setNewNoteError(`The new note must be at least 5 characters long`);
        } else {
            setNewNoteError("");
        }
    };

    return (
        <TextField
            autoFocus
            margin='dense'
            placeholder='New Note'
            id={id}
            label={label}
            type='text'
            value={value}
            onChange={(event) => handleNewNoteInput(event)}
            fullWidth
            error={!!newNoteError}
            helperText={newNoteError}
        />
    );
};

NewNoteInput.propTypes = {
    value: PropTypes.string.isRequired,
    setNewValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    id: PropTypes.string,
};

export default NewNoteInput;
