import "./style.css";
import PropTypes from "prop-types";

const Appointments = ({ apps, sync }) => {
    const totalNumOfPlayers =
        apps.appointments?.reduce(
            (a, b) => a + parseInt(b.numberOfPlayers),
            0
        ) || 0;
    const totalTeams = apps.appointments?.length || 0;
    const totalTestTeams =
        apps.appointments?.reduce((total, appointment) => {
            return total + parseInt(appointment.test);
        }, 0) || 0;

    const lastSync = sync &&
        sync[0] &&
        sync[0]["date"] &&
        sync[0]["time"] && {
            date: sync[0]["date"],
            time: sync[0]["time"],
        };

    return (
        <div className='appointments__container'>
            <h3 id='sync'>Last sync Date: {lastSync && lastSync.date}</h3>
            <h3 id='sync'>Last sync Time: {lastSync && lastSync.time}</h3>
            <div className='appointments__total'>
                <p>
                    Total teams: <span id='total-teams'>{totalTeams}</span>
                </p>
                <p>
                    Total players:{" "}
                    <span id='total-players'>{totalNumOfPlayers}</span>
                </p>
            </div>
            <div className='appointments__test'>
                <p>
                    Test:{" "}
                    <span style={{ color: "#34ebe8" }}>{totalTestTeams}</span>
                </p>
            </div>
        </div>
    );
};

Appointments.propTypes = {
    apps: PropTypes.object.isRequired,
    sync: PropTypes.object.isRequired,
};

export default Appointments;
