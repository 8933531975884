import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import { addNote } from "../../api/notes";
import NewNoteInput from "./NewNoteInput";
import { useState } from "react";
import PropTypes from "prop-types";

const AddNote = ({
    action,
    handleClose,
    fetchNotes,
    selectedAppName,
    selectedVersion,
}) => {
    const [newNote, setNewNote] = useState(undefined);

    const open = action === "add";
    const handleAddNote = async () => {
        if (selectedAppName && selectedVersion && newNote) {
            try {
                const res = await addNote(
                    selectedAppName,
                    selectedVersion,
                    newNote
                );
                if (res.status === "success") {
                    await fetchNotes();
                    handleClose();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Add Note</DialogTitle>
            <DialogContent>
                <div>
                    Add a note in `{selectedVersion}` of{" "}
                    {selectedAppName.toUpperCase()} APP ?
                </div>
            </DialogContent>
            <NewNoteInput
                value={newNote}
                setNewValue={setNewNote}
                label={"Add Note"}
                id={"addNoteInput"}
            />
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='error'>
                    Cancel
                </Button>
                <Button
                    onClick={handleAddNote}
                    disabled={
                        newNote !== undefined &&
                        newNote !== "" &&
                        newNote.length < 5
                    }
                    color='primary'>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddNote.propTypes = {
    action: PropTypes.string,
    handleClose: PropTypes.func,
    fetchNotes: PropTypes.func,
    selectedNote: PropTypes.string,
    selectedAppName: PropTypes.string,
    selectedVersion: PropTypes.string,
};

export default AddNote;
