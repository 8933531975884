import ActionButton from "../ActionButton/ActionButton";
import { TableCell, Tabs, Tab, Box, Typography } from "@mui/material";
import useData from "../../hooks/useData";
import { useState, useEffect } from "react";
import NotesModals from "../Modals/NotesModals";

const NotesSettings = () => {
    const { allAppsNotes, fetchAllAppsNotes } = useData();
    const [notesSelectedTab, setNotesSelectedTab] = useState(0);
    const [selectedAction, setSelectedAction] = useState("");
    const [selectedAppName, setSelectedAppName] = useState(undefined);
    const [selectedVersion, setSelectedVersion] = useState(undefined);
    const [selectedNote, setSelectedNote] = useState(undefined);

    useEffect(() => {
        if (allAppsNotes && allAppsNotes.length > 0) {
            setSelectedAppName(allAppsNotes[0].name);
        }
    }, [allAppsNotes]);

    const handleNotesTabChange = (event, newValue) => {
        setNotesSelectedTab(newValue);
    };

    const resetVersionAndNote = () => {
        setSelectedVersion(undefined);
        setSelectedNote(undefined);
    };

    return (
        <>
            <Tabs
                value={notesSelectedTab}
                onChange={handleNotesTabChange}
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: "#da1a35",
                    },
                }}
                aria-label='Cube Apps Tabs'>
                {allAppsNotes?.map((app, index) => (
                    <Tab
                        key={index}
                        onClick={() => {
                            setSelectedAppName(app?.name);
                            resetVersionAndNote();
                        }}
                        label={`${app?.name} APP`}
                        sx={{
                            color: "black",
                            padding: "0% 2%",
                            "&.Mui-selected": {
                                color: "black",
                            },
                        }}
                    />
                ))}
            </Tabs>
            {allAppsNotes?.[notesSelectedTab]?.versions?.map(
                (version, versionIndex) => (
                    <Box
                        key={versionIndex}
                        sx={{
                            padding: 1,
                            borderBottom: "1px solid black",
                        }}>
                        <Typography
                            variant='h6'
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                            {`v.${version?.name}`}
                            <ActionButton
                                textColor='black'
                                action={() => {
                                    setSelectedAction("add");
                                    setSelectedVersion(version?.name);
                                }}
                                bgColor='#2efddb'
                                ml={5}
                                mr={33}
                                muiColor='primary'
                                text='Add Note'
                                minWidth={125}
                            />
                        </Typography>
                        {version?.notes?.map((note, noteIndex) => (
                            <div
                                key={noteIndex}
                                className='note-text'>
                                <p>{note}</p>
                                <div className='crud-buttons'>
                                    <TableCell
                                        sx={{
                                            display: "inline-flex",
                                        }}>
                                        <ActionButton
                                            textColor='black'
                                            action={() => {
                                                setSelectedAction("edit");
                                                setSelectedNote(note);
                                                setSelectedVersion(
                                                    version?.name
                                                );
                                            }}
                                            bgColor='#2efddb'
                                            ml={5}
                                            mr={2}
                                            muiColor='primary'
                                            text='Edit'
                                            minWidth={125}
                                        />
                                        <ActionButton
                                            textColor='black'
                                            action={() => {
                                                setSelectedAction("delete");
                                                setSelectedNote(note);
                                                setSelectedVersion(
                                                    version?.name
                                                );
                                            }}
                                            bgColor='#da1a35'
                                            ml={5}
                                            mr={2}
                                            text='Delete'
                                            minWidth={125}
                                        />
                                    </TableCell>
                                </div>
                            </div>
                        ))}
                    </Box>
                )
            )}
            <NotesModals
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                selectedAppName={selectedAppName}
                selectedVersion={selectedVersion}
                selectedNote={selectedNote}
                fetchAllAppsNotes={fetchAllAppsNotes}
            />
        </>
    );
};

export default NotesSettings;
