import "./dropdownMenu.css";
import PropTypes from "prop-types";

/**
 * @name ClientsSelector
 * @param {onClientSelect} param0
 * @param {users} param0
 * @returns
 */
const ClientsSelector = ({ onClientSelect, users }) => {
    const handleSelectChange = (event) => {
        event.target.value !== 0 && onClientSelect(event.target.value);
    };

    return (
        <div className='dropdown-box'>
            <select
                className='dropdown-menu'
                onChange={handleSelectChange}>
                <option value={0}>Select Calendar</option>
                {users.map((user, index) => (
                    <option
                        key={index}
                        value={user.clientId}>
                        {user.alias}
                    </option>
                ))}
            </select>
        </div>
    );
};

ClientsSelector.propTypes = {
    onClientSelect: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
};

export default ClientsSelector;
