import config from "../main-config.json";
const URL = config.api_url;

export const getAllAppsNotes = async () => {
    const url = URL + "/notes/getAll.php";
    try {
        const res = await fetch(url);
        const allAppsNotes = await res.json();
        return allAppsNotes.reduce((accumulator, currentValue) => {
            const existingAppIndex = accumulator.findIndex(
                (app) => app.name === currentValue.type
            );
            if (existingAppIndex !== -1) {
                const existingApp = accumulator[existingAppIndex];
                const existingVersionIndex = existingApp.versions.findIndex(
                    (version) => version.name === currentValue.version
                );
                if (existingVersionIndex !== -1) {
                    existingApp.versions[existingVersionIndex].notes = [
                        ...existingApp.versions[existingVersionIndex].notes,
                        ...JSON.parse(currentValue.notes),
                    ];
                } else {
                    existingApp.versions.push({
                        name: currentValue.version,
                        notes: JSON.parse(currentValue.notes),
                    });
                }
            } else {
                accumulator.push({
                    name: currentValue.type,
                    versions: [
                        {
                            name: currentValue.version,
                            notes: JSON.parse(currentValue.notes),
                        },
                    ],
                });
            }

            return accumulator;
        }, []);
    } catch (e) {
        console.error("[getAllAppsNotes]: Failed to Fetch Notes" + e);
        return null;
    }
};

export const addNote = async (appName, version, newNote) => {
    const bodyObj = {
        type: appName,
        version: version,
        text: newNote,
    };
    try {
        const url = URL + "/notes/set.php";
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(bodyObj),
        });
        return await res.json();
    } catch (e) {
        console.error("[addNote]: Failed to add note" + e);
        return null;
    }
};

export const deleteNote = async (appName, version, textToDelete) => {
    const bodyObj = {
        type: appName,
        version: version,
        text: textToDelete,
    };
    try {
        const url = URL + "/notes/delete.php";
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(bodyObj),
        });
        return await res.json();
    } catch (e) {
        console.error("[deleteNote]: Failed to delete note" + e);
        return null;
    }
};

export const editNote = async (
    appName,
    version,
    currentNoteText,
    newNoteText
) => {
    const bodyObj = {
        type: appName,
        version: version,
        text: newNoteText,
        prevTxt: currentNoteText,
    };
    try {
        const url = URL + "/notes/update.php";
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(bodyObj),
        });
        return await res.json();
    } catch (e) {
        console.error("[editNote]: Failed to edit note" + e);
        return null;
    }
};
