import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    AccordionDetails,
    Card,
} from "@mui/material";
import PropTypes from "prop-types";
import "./settings.css";
import NotesSettings from "./notesSettings";

const SettingsTable = ({ type, tableHeaders, alignment = false, children }) => {
    return (
        <AccordionDetails className='accord_details'>
            <Card
                className='w-100'
                sx={{ minWidth: 275 }}>
                {type === "operations" && (
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label='simple table'>
                        <TableHead>
                            {tableHeaders?.map((header, index) => (
                                <TableCell
                                    align={
                                        alignment && index !== 0
                                            ? "right"
                                            : "left"
                                    }
                                    key={index}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableHead>
                        <TableBody>{children}</TableBody>
                    </Table>
                )}
                {type === "notes" && <NotesSettings />}
            </Card>
        </AccordionDetails>
    );
};

SettingsTable.propTypes = {
    type: PropTypes.oneOf(["operations", "notes"]).isRequired,
    tableHeaders: PropTypes.arrayOf(PropTypes.string),
    alignment: PropTypes.bool,
    children: PropTypes.node,
};

export default SettingsTable;
