import EditNote from "./EditNote";
import AddNote from "./AddNote";
import DeleteNote from "./DeleteNote";
import PropTypes from "prop-types";

const NotesModals = ({
    selectedAction,
    setSelectedAction,
    selectedAppName,
    selectedVersion,
    selectedNote,
    fetchAllAppsNotes,
}) => {
    const handleClose = () => {
        setSelectedAction("");
    };

    return (
        <>
            {selectedAction === "delete" && (
                <DeleteNote
                    action={selectedAction}
                    handleClose={handleClose}
                    fetchNotes={fetchAllAppsNotes}
                    selectedAppName={selectedAppName}
                    selectedVersion={selectedVersion}
                    selectedNote={selectedNote}
                />
            )}
            {selectedAction === "edit" && (
                <EditNote
                    action={selectedAction}
                    handleClose={handleClose}
                    fetchNotes={fetchAllAppsNotes}
                    selectedAppName={selectedAppName}
                    selectedVersion={selectedVersion}
                    selectedNote={selectedNote}
                />
            )}
            {selectedAction === "add" && (
                <AddNote
                    action={selectedAction}
                    handleClose={handleClose}
                    fetchNotes={fetchAllAppsNotes}
                    selectedAppName={selectedAppName}
                    selectedVersion={selectedVersion}
                />
            )}
        </>
    );
};

NotesModals.propTypes = {
    selectedAction: PropTypes.string,
    setSelectedAction: PropTypes.func,
    fetchAllAppsNotes: PropTypes.func,
    selectedAppName: PropTypes.string || undefined,
    selectedVersion: PropTypes.string || undefined,
    selectedNote: PropTypes.string || undefined,
};

export default NotesModals;
